import { Component, OnInit } from '@angular/core';
import { ApiService } from '../services/api.service';
import { AuthService } from '../services/auth.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import tippy from 'tippy.js';
import { GlobalVariables } from '../services/local/globalVariables.service';




@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})
export class MainComponent implements OnInit {

  private token: string
  workplaces: Array<any> = []
  filterValue = ''
  title="Dashboard";
  imagepath: string=''
  clientInfo: any
  idDetail: any
  calendarDetail:any


  constructor(private api: ApiService, private router: Router, private globalVariables: GlobalVariables, private auth:AuthService, public activatedRouter: ActivatedRoute,) { }

  ngOnInit(): void {

      this.api.service('/workplaces','get').subscribe(res=>{
        if (res) {
          if (res.workPlaces.length === 1) {
            this.clickWorkPlace(res.workPlaces[0].idWorkPlace, res.workPlaces[0].name,res.workPlaces[0].address,res.workPlaces[0].inhouseWorkPlace,res.workPlaces[0].phone,res.workPlaces[0].enteprise );
            return;
          }
          res.workPlaces.forEach(workPlace => this.workplaces.push(workPlace));
        }
       
      
      })


    this.imagepath=this.globalVariables.getUrlImage()

    this.activatedRouter.queryParams.subscribe(params=>{
      console.log(params,'params')
      if(params['calendarDetail']){
        this.calendarDetail = params['calendarDetail']
      }
     }
    ) 
      
      // 

    // tippy('#myButton', { content: "I'm a Tippy tooltip!" ,
    //  placement: 'top',
    // trigger: 'hover'
    //})
 

  }

  clickWorkPlace(idWorkPlace: any, name: any,address:string, inhouseWorkPlace?: boolean, phoneWorkPlace?:string, enterprise?: number, clientInfo? :any) {
       this.globalVariables.setphoneWorkPlace(phoneWorkPlace)
       localStorage.setItem('phoneWorkPlace', phoneWorkPlace)
       localStorage.setItem('inhouseWorkPlace', inhouseWorkPlace.toString())
      
       this.router.navigate(['resources/' + idWorkPlace + '/' + name], {queryParams:{address:address, idWorkPlace:idWorkPlace, enterprise:enterprise, calendarDetail:this.calendarDetail,name:name}})
     // this.router.navigate(['resources/' + id + '/' + name], {queryParams:{address:workPlace[0].address, idWorkPlace:workPlace[0].idWorkPlace}})
    // this.router.navigate(['resources'])
  }

  clickExit(event) {
    event.preventDefault();
    localStorage.clear()
    this.router.navigate(['login']);
  }

  handleSearch(event) {
    this.filterValue = event

  }


  ngAfterViewInit() {
    tippy('#myButton', {
      content: "I'm a Tippy tooltip!",
      placement: 'top',
      trigger: 'hover'
    })
  }

  openAsideMenu(event){
    document.getElementById("sideNavigation").style.display="flex";
    document.getElementById("close").style.display = "flex";
    document.getElementById("backdrop").style.display = "flex";
   
  }

  closeNav(event){

    event.stopPropagation();
    event.preventDefault();
    document.getElementById("sideNavigation").style.display = "none";
    document.getElementById("close").style.display = "none";
    document.getElementById("backdrop").style.display = "none";
  
  }

}