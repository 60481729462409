export class CalendarDetails
{
        workPlaceId         : any;
        workPlace           : any;
        workPlaceName       : any;
        calendar            : any;
        idDetail            : any;
        description         : any;
        allDay              : boolean;
        title               : string;
        dateDefault         : any;
        endDate             : any;
        resourceId          : any;  
        nameresourceId      : any; 
        calendarsNamesArr   : Array<any>=[];
        resourceIds         : Array<any>;
        repeatParams        : any;
        startTimeWorkplace  : any;
        currentDateRepeat   : any;
        extendedProps       : any;
        servicesIds         : Array<any>;
        taskIds             : string;
        customerInfo        : any;
      

    constructor(
        workPlaceId   :any,
        workPlace     : any,
        workPlaceName : any,
        calendar      : any,
        idDetail      : any,
        description   : any,
        allDay        : boolean,
        title         : string,
        dateDefault   : any,
        endDate       : any,
        resourceId    : any,  
        nameresourceId: any, 
        calendarsNamesArr: Array<any>=[],
        resourceIds   : Array<any>,
        repeatParams  : any,
        startTimeWorkplace: any,
        currentDateRepeat : any,
        extendedProps : any,
        servicesIds   : Array<any>,
        taskIds       : string,
        customerInfo  : any,
  
    ){


        this.workPlaceId        = workPlaceId,
        this.workPlace          = workPlace
        this.workPlaceName      = workPlaceName,
        this.calendar           = calendar
        this.idDetail           = idDetail,
        this.description        = description,
        this.allDay             = allDay,
        this.title              = title,
        this.dateDefault        = dateDefault,
        this.endDate            = endDate,
        this.resourceId         = resourceId,  
        this.nameresourceId     = nameresourceId, 
        this.calendarsNamesArr  = calendarsNamesArr,
        this.resourceIds        = resourceIds,
        this.repeatParams       = repeatParams,
        this.startTimeWorkplace = startTimeWorkplace,
        this.currentDateRepeat  = currentDateRepeat,
        this.extendedProps      = extendedProps,
        this.servicesIds        = servicesIds,
        this.taskIds            = taskIds ,
        this.customerInfo       = customerInfo
   
        

    }





}