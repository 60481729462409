<div class="modal-sm">

    <div  class="eventeditor-sm eventeditor-search">
            <a (click)='closeEvent($event)' class="button ghost close-icon">
                <i class="sprite-event-close" style="background:url('assets/img/close-black.svg')"></i>
            </a>
            <div class="main-title">
                <h3>Buscar Presupuesto</h3>
            </div>
            <div class="search-budget">
               <div class="content-budget ">
                   <div class="form-group col-md-4 col-md-4-sm">
                       <label>Serial</label>
                       <div class="flex-row">
                           <input class="form-control-budget" type="text"  [(ngModel)]="serial" name="search" autocomplete="off" placeholder="Serial" (keyup.enter)="onKey(searchText)">
                       </div>
                   </div>
                   <div class="form-group col-md-4 col-md-4-sm" style="flex-basis:0%">
                    <label></label>
                    <div class="flex-row" style="justify-content:center">/
                    </div>
                </div>
                   <div class="form-group col-md-4 col-md-4-sm">
                       <label>Número</label>
                       <div class="flex-row">
                           <input class="form-control-budget" type="text"  [(ngModel)]="budgetNumber" name="search" autocomplete="off" placeholder="número" (keyup.enter)="onKey(searchText)">
                     
                       </div>
                   </div>
                   <div class="form-group col-md-4 col-md-4-sm content-search-budget desktop">
                       <label style="height:20px"></label>
                       <div class="flex-row">
                           <button class="button-budget button-budget-search desktop" (click)="searchBudget($event)" type="submit">Buscar</button>
                           <a class="link clear"  href="#" *ngIf="serial || budgetNumber" (click)="clearBudgets($event)">
                               <i class="sprite-event-close" style="background: url('assets/img/icons8-cancelar.svg');"></i>
                           </a>
                       </div>
                   </div>
               </div>
               <div class="form-group col-md-4 col-md-4-sm mobile" style="display: flex; flex-direction: column; justify-content:center">
                <label></label>
                <div class="flex-row">
                    <button class="button-budget button-budget-search mobile" (click)="searchBudget($event)" type="submit">Buscar</button>
                    <a class="link clear mobile"  href="#" *ngIf="serial || budgetNumber" (click)="clearBudgets($event)">
                        <i class="sprite-event-close" style="background: url('assets/img/icons8-cancelar.svg');"></i>
                    </a>
                </div>
            </div>
           </div>
          <!---- <div class="table-results">-->
                  <!--<div  class="header" >-->

                  <!---- <div class="row" style="width:100%">-->
                  <!---- </div>-->
                  
              <!---- </div>-->
            
               <div class="body">
                   <div *ngIf="loading" style="display:flex; justify-content: center; align-items: center; width:inherit" >
                       <mat-spinner [diameter]="25"></mat-spinner>
                   </div>
                   <div *ngIf=" !loading && !error && budgets.length==0" class="text-no-found">
                       <p>{{msg}}</p>
                   </div>
             
   
                   <div *ngIf="budgets.length>0" class="desktop" style="width:100%">
                       <div *ngFor="let item of budgets, let i=index" class="row" style="width:100%; justify-content:space-between; margin-bottom:10px"  [ngClass]="{ 'resaltado': selectedDiv === i }" (click)="highlightDiv(i)">
                           <div  class="cell cell-1 value pd-left" [ngClass]="{ 'resaltado': selectedDiv === i }">
                             {{item.budget}} 
                           </div>
                          <div *ngIf="selectedDiv === i " class="cell cell-4 cell-4-header" style="justify-content:flex-end" >
                                 
                                  <a class="link" (click)="selectBudget($event,item)" >
                                       <i class="sprite-budget-check-white text-link" style="
                                           background:url('assets/custom/{{imagepath}}/icono/check.svg')">
                                       </i>
                                   </a>
                                
                           </div>
                     </div> 
                   </div>


                   <div *ngIf="budgets.length>0" class="mobile" style="width:100%">
                    <div *ngFor="let item of budgets, let i=index" class="row" style="width:100%; justify-content:space-between;margin-bottom:10px"  [ngClass]="{ 'resaltado': selectedDiv === i }" (click)="highlightDiv(i)">
                        <div  class="cell cell-1 value pd-left" [ngClass]="{ 'resaltado': selectedDiv === i }">
                          {{item.budget}} 
                        </div>
                       <div *ngIf="selectedDiv === i " class="cell cell-4 cell-4-header" style="justify-content:flex-end" >
                              
                               <a class="link" (click)="selectBudget($event,item)" >
                                    <i class="sprite-budget-check-white text-link" style="
                                        background:url('assets/custom/{{imagepath}}/icono/check.svg')">
                                    </i> 
                                </a>
                             
                        </div>
                  </div> 
                </div>
               
               <div *ngIf="error" class="message-budget">
                   <p>{{msg}}</p>
               </div>
           </div>
    </div>
</div>