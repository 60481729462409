import {NgModule,LOCALE_ID} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {FullCalendarModule} from 'primeng/fullcalendar';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {TextFieldModule} from '@angular/cdk/text-field'
import {MatDividerModule} from '@angular/material/divider';
import {MatToolbarModule} from '@angular/material/toolbar';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { CalendarPickerComponent} from './calendar-picker/calendar-picker.component';
import localeEs from '@angular/common/locales/es';
import {registerLocaleData } from '@angular/common';
import {LoginComponent} from './access/login/login.component';
import {MainComponent} from './main/main.component';
import {FormsModule} from '@angular/forms';
import {ApiService} from './services/api.service';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import {GlobalVariables} from './services/local/globalVariables.service'
import {DatePickerComponent} from './shared/lib/components/datepicker/date-picker.component';
import {DatePickerRecurrenceComponent} from './shared/lib/components/datepicker-recurrence/date-picker-recurrence.component';
import {FilterPipe} from './pipes/filter.pipe';
import {SearchComponent} from './shared/lib/components/search/search/search.component';
import {MatFormFieldModule, FloatLabelType, MAT_FORM_FIELD_DEFAULT_OPTIONS} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {ReactiveFormsModule} from '@angular/forms';
import {ResourcesViewCalendarComponent } from './resources-view-calendar/resources-view-calendar.component'
import {CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import {AddEventModalComponent} from './add-event-modal/add-event-modal.component';
import {MatDialogModule, MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ModalService} from './services/modal-service/modal-service.service';
import {AddNewEventsComponent} from './resources-view-calendar/add-new-events/add-new-events.component';
import { AddNewEventsAssociateComponent } from './resources-view-calendar/add-new-events-copy/add-new-events-associate.component';
import {UtilsService} from './services/utils.service';
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {ShowMessageComponent} from './shared/modal/show_message/show-message.component';
import {MatMenuModule} from '@angular/material/menu';
import {RecurrenceEventsComponent } from './shared/lib/components/recurrence-event/recurrence-events.component';
import {MatTabsModule} from '@angular/material/tabs';
/*import {WorkplaceSchedulesComponent } from './panel/workplace-schedules/workplace-schedules.component';
/*import {PanelComponent} from './panel/panel.component';*/
/*import {AsideMenuComponent} from './shared/layouts/asides/aside-menu/aside-menu.component';*/
import {MainMenuHeaderComponent} from './shared/layouts/headers/main-menu-header/main-menu-header.component';
import {SearchEventsComponent} from './shared/modal/search-events/search-events.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { ToastModule } from 'primeng/toast';
import { MatSnackBarModule} from '@angular/material/snack-bar';
import { SeriesEventsComponent } from './shared/modal/series-events/series-events.component';
import { ShareEventsComponent } from './shared/modal/share-events/share-events.component';
import { MatPaginatorModule} from '@angular/material/paginator';
/*import { AsideMenuMobileComponent } from './shared/layouts/asides/aside-menu-mobile/aside-menu-mobile.component';*/
import { AsideGralMenuMobileComponent } from './shared/layouts/asides/aside-gral-menu-mobile/aside-gral-menu-mobile.component';
import { ConfigService } from './services/config.service';
import { SafePipe } from './pipes/safe.pipe';
import { APP_INITIALIZER } from '@angular/core';
import { BottomSheetComponent } from './shared/notifications/bottom-sheet/bottom-sheet.component';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MultiSelectOptionsComponent } from './shared/lib/components/multi-select-options/multi-select-options.component';
import { SearchCustomerComponent } from './shared/modal/search-customer/search-customer/search-customer.component';
import { SearchBudgetComponent } from './shared/modal/search-budget/search-budget.component'



/*import { WorkplaceCalendarsComponent } from './panel/workplace-calendars/workplace-calendars.component';*/


function initializeApp(appConfig: ConfigService) { return () => appConfig.configuracion;}



registerLocaleData(localeEs,'es')




@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [
    AppComponent,
    CalendarPickerComponent,
    LoginComponent,
    MainComponent,
    DatePickerComponent,
    FilterPipe,
    SearchComponent,
    ResourcesViewCalendarComponent,
    AddEventModalComponent,
    AddNewEventsComponent,
    AddNewEventsAssociateComponent,
    ShowMessageComponent,
    RecurrenceEventsComponent,
    /*WorkplaceSchedulesComponent,*/
    /*PanelComponent,*/
    /*AsideMenuComponent,*/
    MainMenuHeaderComponent,
    DatePickerRecurrenceComponent,
    SearchEventsComponent,
    SeriesEventsComponent,
    /*AsideMenuMobileComponent,*/
    AsideGralMenuMobileComponent,
    SafePipe,
    BottomSheetComponent,
    ShareEventsComponent,
    MultiSelectOptionsComponent,
    SearchCustomerComponent,
    SearchBudgetComponent
    /*WorkplaceCalendarsComponent*/
   
  
   
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FullCalendarModule,
    MatSidenavModule,
    MatButtonModule,
    MatIconModule,
    MatDividerModule,
    MatToolbarModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MatDialogModule,
    DragDropModule,
    MatMenuModule,
    MatTabsModule,
    TextFieldModule,
    MatProgressSpinnerModule,
    ToastModule,
    MatSnackBarModule,
    MatPaginatorModule,
    MatBottomSheetModule

 
  
  ],
  providers: [{provide: LOCALE_ID, useValue:'es'}, ApiService, GlobalVariables, AddEventModalComponent,MatDialogModule,{provide: MAT_DIALOG_DATA, useValue: {}},{ provide: MatDialogRef, useValue: {} }, ModalService, AddNewEventsComponent,AddNewEventsAssociateComponent, UtilsService, ConfigService, SafePipe,  {provide: APP_INITIALIZER,
    multi: true,
    deps: [ConfigService],
    useFactory :(configService: ConfigService)=>()=>configService.loadAppConfig()
    }],
  bootstrap: [AppComponent],
  entryComponents: [AddEventModalComponent, AddNewEventsComponent,AddNewEventsAssociateComponent]
})
export class AppModule { }
