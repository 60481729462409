import { Component, OnInit, Output,Input, EventEmitter} from '@angular/core';
import { ApiService } from '../../../services/api.service';
import { AbstractControl, FormControl, ValidatorFn} from '@angular/forms';
import { FormBuilder, FormGroup, Validators} from '@angular/forms';
import { BottomSheetComponent } from '../../notifications/bottom-sheet/bottom-sheet.component';
import { MatBottomSheet} from '@angular/material/bottom-sheet';




@Component({
  selector: 'app-share-events',
  templateUrl: './share-events.component.html',
  styleUrls: ['./share-events.component.css']
})



export class ShareEventsComponent implements OnInit {
    constructor(private api: ApiService,public fb: FormBuilder, private  bottomSheet: MatBottomSheet) { }
    @Output() closeShareEvent = new EventEmitter();
    @Input()  htmlMessage
    @Input()  workPlaceName
    @Input()  phone 
  
    error:string=""
    success: string=""
    code:any='+34'
    message: any
    myForm: FormGroup;
   

    ngOnInit(): void {


      this.message = <HTMLInputElement>  document.getElementById("message") 
      this.message.value=this.htmlMessage

   
      this.myForm = this.fb.group({
        code: new FormControl('+34'),
        phone: ['', [Validators.required , Validators.pattern, Validators.minLength(9), Validators.maxLength(9)]],
      });

     
    }
   

    sendEvent(e){
      e.preventDefault()
      e.stopPropagation();
      
      this.error=""
      this.success='Error'
      const phoneHtmlElement=this.myForm.get("phone")


      if (this.myForm.value.code) this.code= this.myForm.value.code.substring(1,this.myForm.value.code.length)
     
      
      if (this.myForm.valid)
      {
        const data={'message':this.message.value,'phone':this.code + this.phone, 'profile':'TEST1', 'type':'text'}
        this.api.service('/message', 'post', data).subscribe(res=>(
        console.log(res,'enviado mensaje whatsapp')))
        this.success='OK'
        this.openBottomSheet({'title': `<div> Mensaje enviado con éxito </div>`,'type_actions':'close','succeed':'true' },{})
       
      }
      else
      {
        if (phoneHtmlElement.hasError('required'))
          this.error="* El campo teléfono es requerido"
        else if( phoneHtmlElement.hasError('pattern'))
          this.error= "* El número de móvil no es válido"
        else if ((phoneHtmlElement.hasError('minlength'))|| phoneHtmlElement.hasError('maxlength')) 
          this.error="* El campo teléfono debe cumplir con la longitud de 9 dígitos"
      }


     // if (this.phone==null || this.phone=="")
     //   this.error="*Debe especificar un número teléfono"
    //  else 
      //  this.error=""

       // const data={'message':"hola",'phone':"34644074320"}
      //this.api.sendMessages(data).subscribe(res=>(
      // console.log(res,'enviado mensaje whatsapp')))
    }



    openBottomSheet(data:any, data_event: any, idDetail?:any,start?:any,end?:any) {
      let sheetRef =  this.bottomSheet.open(BottomSheetComponent, {
        data: data
      });
      sheetRef.afterDismissed().subscribe( res => {
        if (data.succeed=='true')
          this.closeShareEvent.emit({result:'OK'})
        else
          this.closeShareEvent.emit({result:'ERROR'})
      });
  }

    closeEvent(event){
      this.closeShareEvent.emit()
    }
  
}



