import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-multi-select-options',
  templateUrl: './multi-select-options.component.html',
  styleUrls: ['./multi-select-options.component.css']
})
export class MultiSelectOptionsComponent implements OnInit {
  @Input() public arrOptions: Array<any>;
  @Input() public sel: any;
  @Input() public group: any;
  @Input() public arrNewSelects : Array<any>;
  @Input() public controlName: any;


  @Output() public deleteSel = new EventEmitter<boolean>();
  @Output() public arrNewSelectsOut = new EventEmitter<any>();

  public field1:any
  public groupSelect: any

  constructor() { }

  ngOnInit(): void {


    this.groupSelect=this.group.group

    this.field1=this.sel.value

    if (this.arrNewSelects)
    {
      if (this.arrNewSelects.length==0)
      {
        this.arrNewSelects=[{id:this.arrNewSelects.length, value:''}]
      }

    }
   else
     this.arrNewSelects=[{id:0, value:''}]

   
    
  }

  setValue(event){

    event.stopPropagation()
    event.preventDefault()

    if (this.arrNewSelects.length==0)
    {
      this.arrNewSelects.push({value:this.field1})

    }
     

    else
      this.arrNewSelects[this.sel.id].value=this.field1
 
    return this.arrNewSelectsOut.emit(this.arrNewSelects)

  }

  deleteOption(){

    let arrNewSelectsCloned: Array<any>=[]
    this.arrNewSelects.splice(this.sel.id,1)
    this.arrNewSelects.forEach((item,idx)=>{
      arrNewSelectsCloned.push({id:idx, value: item.value})
    })
    this.arrNewSelects=arrNewSelectsCloned
    this.arrNewSelectsOut.emit(this.arrNewSelects)
  

  }

}
