import { Component, Input, Output, OnInit, EventEmitter, ViewChild, ElementRef, SimpleChanges } from '@angular/core';
import { GlobalVariables } from 'src/app/services/local/globalVariables.service';
import { UtilsService } from 'src/app/services/utils.service';


@Component({
  selector: 'app-show-message',
  templateUrl: './show-message.component.html',
  styleUrls: ['./show-message.component.css']
})
export class ShowMessageComponent implements OnInit {

  
  @Input() message: any;
  @Input() action:any

  @Output() closeMessageEvent = new EventEmitter();
  @Output() cancelMessageEvent = new EventEmitter();
  @Output() deleteMessageEvent = new EventEmitter();
  @Output() confirmMessageEvent = new EventEmitter();

  title: string=''

  constructor(private utilsService:UtilsService)  {}


  ngOnInit(): void {

    switch (this.action){
      case 'delete':
        this.title='Eliminar evento'
        break;
      case 'serie':
        this.title='Evento serie'
        break;
      case 'done':
        this.title='Confirmación'
        break;
      case 'alert':
        this.title='Alerta'
        break;
      case 'info':
          this.title='Aviso'
          break;
      default:
        this.title='Notificación'
        break;
    }
     
      

      

   /* if (this.action=='delete')
    this.title='Eliminar evento'
    else this.title='Evento serie'*/
   
  }


  deleteEvent(event){
    this.deleteMessageEvent.emit({ result: "eliminar"})                                      
  }

  closeEvent(event){
    this.closeMessageEvent.emit()
  }

  cancelEvent(event){
    this.cancelMessageEvent.emit({result: 'cancelar'})
  }

  confirmEvent(event){
    this.confirmMessageEvent.emit({result:'update'})

  }

 

 
}












