import { Component, OnInit, Output,Input, EventEmitter} from '@angular/core';
import { transfromDateEN, transfromStringtoDate, transfromStringtoDateTime, validateDates} from '../../../shared/utilities/utilities';
import { ApiService } from '../../../services/api.service';
import { UtilsService} from '../../../services/utils.service';
import { AddNewEventsComponent } from 'src/app/resources-view-calendar/add-new-events/add-new-events.component';
import { AddNewEventsAssociateComponent } from 'src/app/resources-view-calendar/add-new-events-copy/add-new-events-associate.component';
import { ToastService } from '../../../services/toast-service/toast-service.service';
import { MatSnackBar} from '@angular/material/snack-bar';
import { GlobalVariables } from 'src/app/services/local/globalVariables.service';
import { CalendarDetails } from '../../models/calendarDetails';



@Component({
  selector: 'app-search-events',
  templateUrl: './search-events.component.html',
  styleUrls: ['./search-events.component.css']
})

export class SearchEventsComponent implements OnInit {
  @Output() confirmMessageEvent = new EventEmitter();
  @Output() closeMessageEvent = new EventEmitter();
  @Output() cancelMessageEvent = new EventEmitter();
  @Input() idWorkPlace: any;
  @Input () view: any;
  @Input() workPlace: any;


 
  isActiveFrom: any =false;
  isActiveTo: any = false;

  dateTo: any='';
  dateFrom: any='';

  searchText: any =""

  calendars:Array<any>=[]
  //calendarDetails: Array<any>=[]

  resources: any
  resourceIds: Array <any>
  events: Array<any>=[]

  calendarsNamesArr: Array<any>=[];
  namecalendarId: string='';
  loading: boolean;
  data: any;
  idCalendarDetail: any;
  error: boolean;
  msg: string;
  startTimeWorkplace:any;
  associate: boolean;

  calendarDetails:CalendarDetails



  constructor(private api: ApiService, private utilsService:UtilsService, private toastservice:ToastService, private _snackBar: MatSnackBar, private globalVariables:GlobalVariables ) { }

  ngOnInit(): void {
    this.initializeValues()
    this.dateFrom= new Date()
    this.dateTo= new Date()
    this.dateTo.setMonth(this.dateTo.getMonth() + 1);
    this.dateFrom=transfromStringtoDate(this.dateFrom)
    this.dateTo=transfromStringtoDate(this.dateTo)
    this.startTimeWorkplace= this.globalVariables.getstartTimeWorkplace()


    if (localStorage.getItem('inhouseWorkPlace')=='true')
      this.associate=false
    else
      this.associate=true
   
  }

  closeEvent(event){
    this.closeMessageEvent.emit({result:'gotoDate', datefrom: this.dateFrom, dateTo: this.dateTo})
  }

  cancelEvent(event){
   this.cancelMessageEvent.emit({result: 'cancelar'})
  }

  setActive(value1?: boolean, value2?: boolean){  


    if(value1)
    { this.isActiveFrom=value1;
      this.isActiveTo=false
     }
  
    if(value2)
    { this.isActiveTo=value2;
      this.isActiveFrom=false
    
    }

    this.closeComponent()

  }

  closeComponent(){
    if (this.isActiveFrom){
      document.addEventListener('click', function(e) {
        if(document.getElementById('select-dateFrom') !=null)
        {
          if (document.getElementById('select-dateFrom').contains(e.target as Node)) {
            document.getElementById('dateFrom-picker').style.display="block"
           
          } 
          else {
            if(document.getElementById('next')!=null){
              if(document.getElementById('next').contains(e.target as Node)) {
                if(document.getElementById('dateFrom-picker') !=null){
                  document.getElementById('dateFrom-picker').style.display="block"
                }
               }
               else
               {
                if(document.getElementById('prev')!= null){
                  if(document.getElementById('prev').contains(e.target as Node)) {
                    if(document.getElementById('dateFrom-picker') !=null){
                      document.getElementById('dateFrom-picker').style.display="block"
                    }
                  }
                  else
                  {
                    if (!document.getElementById('select-dateFrom').contains(e.target as Node)){
                      if( document.getElementById('dateFrom-picker')!=null)
                      document.getElementById('dateFrom-picker').style.display="none"
                    }

                  }
                }
               }
            }
          }
        }
      })
    }


   if (this.isActiveTo){
      document.addEventListener('click', function(e) {
        if(document.getElementById('select-dateTo') !=null)
        {
          if (document.getElementById('select-dateTo').contains(e.target as Node)) {
            document.getElementById('dateTo-picker').style.display="block"
      
          } 
          else {
            if(document.getElementById('next')!=null){
              if(document.getElementById('next').contains(e.target as Node)) {
                if(document.getElementById('dateTo-picker') !=null){
                  document.getElementById('dateTo-picker').style.display="block"
                }
              }
              else{
                if(document.getElementById('prev')!=null){
                  if(document.getElementById('prev').contains(e.target as Node)) {
                    if(document.getElementById('dateTo-picker') !=null){
                      document.getElementById('dateTo-picker').style.display="block"
                    }
                  }
                  else {
                      if  (!document.getElementById('select-dateTo').contains(e.target as Node)){
                        if( document.getElementById('dateTo-picker')!=null)
                            document.getElementById('dateTo-picker').style.display="none" 
                      }
                   }    
                } 
              }
            }    
          }
        }
      })
    }  
  }

  clickDateSelected(event){

    if(this.isActiveTo){
       this.dateTo= transfromStringtoDate(event)
       this.isActiveTo=false
     }
     
    if(this.isActiveFrom){
      this.dateFrom= transfromStringtoDate(event)
      /*this.dateTo= transfromStringtoDate(event)*/
      this.isActiveFrom=false
     }              
  }

    searchEvent(event?){
      this.initializeValues()

      if (validateDates(this.dateFrom, this.dateTo,'/'))
        {
          if(this.searchText!='')
          {
            this.loading=true
            this.createEvents()
            if (this.events.length && this.events.length==0)
              this.loading=false
             

          }
          else
           this.error=false
           this.msg='No hay eventos con el criterio de búsqueda actual'
           return;
         
        }
        else
           this.error=true
           this.msg='La fecha/ hora Desde no puede ser mayor que la fecha/hora Hasta'
           return;
       
       
    } 

    initializeValues(){
      this.calendars=[];
     // this.calendarDetails=[];
      this.resources=[];
      this.events=[];
      this.calendars=[];
      this.error=false;
      this.msg='No hay eventos con el criterio de búsqueda actual'
    }
    
    goToModalEditEvent(event, item){

      event.stopPropagation();
      event.preventDefault();
      let services: Array<any>=[];
      let customerInfo   : any ={};
      services = this.createServices(item.services)
      let modalRef: any


      
      this.resourceIds=this.createResourceIdsArr(item.id)
      this.namecalendarId=this.getCalendarIdName(item.resourceId)
      this.createArrCalendarsName()
      customerInfo       =  {customer:{NOMCLTE:item.client? item.client.firstName:'',TELEFONO:item.phone? item.phone:'', idClient:item.client?item.client.idClient:'', REGISTRATION:item.registration, EMAIL:item.client?item.client.email:'', budget:item.budget?item.budget:'', orFleet:item.orFleet?item.orFleet:''}}
      
      this.calendarDetails= new CalendarDetails(this.workPlace.idWorkPlace,this.workPlace,this.workPlace.name,'',item.id,item.extendedProps.description,false,item.title,item.dateFromModal, item.dateToModal,item.resourceId,this.namecalendarId,this.calendarsNamesArr,this.resourceIds,item.repeatParams,this.startTimeWorkplace,item.dateFrom,item.extendedProps,services,'',customerInfo )
      
      if(!this.associate)
        //modalRef= this.utilsService.openModalAddNewEventorg(AddNewEventsComponent,this.view,item.title, item.extendedProps.description,this.startTimeWorkplace, item.dateFromModal, item.dateToModal, this.namecalendarId, item.resourceId, this.calendarsNamesArr, false, item.id, this.resourceIds, item.repeatParams, item.dateFrom, item.extendedProps,'',services,customerInfo,'',this.workPlace)
        modalRef= this.utilsService.openModalAddNewEvent(AddNewEventsComponent,this.view,this.calendarDetails)
      
      else
        modalRef= this.utilsService.openModalAddNewEventAssociate(AddNewEventsAssociateComponent,this.view, this.calendarDetails)
       // modalRef= this.utilsService.openModalAddNewEventAssociate(AddNewEventsAssociateComponent,this.view,item.title, item.extendedProps.description,this.startTimeWorkplace, item.dateFromModal, item.dateToModal, this.namecalendarId, item.resourceId, this.calendarsNamesArr, false, item.id, this.resourceIds, item.repeatParams, item.dateFrom, item.extendedProps,'')

      modalRef.subscribe(res=>{

        if(res){
          switch(res["result"]){
            case "aceptar":
              this._snackBar.open('Se han guardado los cambios', 'cerrar');
              //this.closeMessageEvent.emit({result:'editEvent', data: res.data, resourceIds: this.resourceIds, idCalendarDetail: item.id})
             // this.closeMessageEvent.emit({result:'gotoDate', datefrom: this.dateFrom, dateTo: this.dateTo})
              //this.confirmMessageEvent.emit({result:'editEvent', data: res.data, resourceIds: this.resourceIds, idCalendarDetail: item.id})
             
              break;

               }
          }

      })
    
    }

    goToCalendar(event, date: any){

      event.stopPropagation();
      event.preventDefault();
      this.closeMessageEvent.emit({result:'gotoDate', datefrom: date, dateTo: date})

    }

    createResourceIdsArr(idEvent:any):Array<any>{
      var j:number=0
      var resourceIds: Array<any>=[]
    
      this.events.forEach((i) => {
        
        if(i.id==idEvent && j!=this.events.length){
          i.calendars.forEach(calendars => {
            resourceIds.push({
              idCalendar: calendars.calendar.idCalendar,
              name:calendars.calendar.name?calendars.calendar.name:calendars.calendar.shortName
             }  
            )
          }); 
          
          j=this.events.length
        }   
      });
    
      return resourceIds
    }

    createArrCalendarsName(){
      this.calendars.forEach(calendar => {
      const {idCalendar, name, shortName} = calendar;
      this.calendarsNamesArr.push({'name': (name?name :shortName), 'idCalendar': idCalendar}) 
    });
  
    return this.calendarsNamesArr;  
  }

    createServices(arrServices){
      let services=[]
   
      arrServices.forEach(val=>{
        services.push({idService:val.service.idService, name:val.service.name})
      })

      return services
    }

    getCalendarIdName(id:number):string{
      const {name, shortName} = this.calendars.filter(calendar => calendar.idCalendar==id)[0];
      this.namecalendarId = (name?name :shortName);
      return this.namecalendarId;  
    }


    createEvents(){
      let data={
        "dateTimeFrom": transfromDateEN(this.dateFrom),
        "dateTimeTo": transfromDateEN(this.dateTo),
        "textFind": this.searchText
      }
      //this.api.getCalendarsByWorkPlaceByDay(this.idWorkPlace,data)
      this.api.service('/calendarsByWorkPlace/'+ this.idWorkPlace,'post',data).subscribe(res=>{
       res.calendars.forEach((calendar, i) => {
        this.calendars.push(calendar);
        const {idCalendar, name, shortName} = calendar;
         this.resources.push({
          id:idCalendar.toString(), 
          title: (name ?name :shortName), 
        });

        calendar.calendarDetails.forEach((calendarDetail) => {
          this.loading=true
          this.events.push({
            id:calendarDetail.calendarDetail.idCalendarDetail, 
            resourceId: idCalendar, 
            start: calendarDetail.date + 'T' + calendarDetail.calendarDetail.timeFrom, 
            end: calendarDetail.date + 'T' + calendarDetail.calendarDetail.timeTo, 
            title: calendarDetail.calendarDetail.title,
            extendedProps: {
              description: calendarDetail.calendarDetail.description,
              date:calendarDetail.date,
              image:"assets/img/check.svg",
              idUserCreate:calendarDetail.calendarDetail.idUserCreate,
              idUserLastUpdate:calendarDetail.calendarDetail.idUserLastUpdate,
              createdDate: calendarDetail.calendarDetail.createAt,
              updatedDate: calendarDetail.calendarDetail.updatedAt 
           
            },
              calendars:calendarDetail.calendarDetail.calendars,
              dateFromModal:calendarDetail.calendarDetail.dateFrom + 'T' + calendarDetail.calendarDetail.timeFrom, 
              dateToModal: calendarDetail.calendarDetail.dateTo + 'T' + calendarDetail.calendarDetail.timeTo, 
              repeatParams: {repeat                :  calendarDetail.calendarDetail.repeat,
                             repeatAlways          :  calendarDetail.calendarDetail.repeatAlways,
                             repeatDuring          :  calendarDetail.calendarDetail.repeatDuring,
                             repeatEvery           :  calendarDetail.calendarDetail.repeatEvery,
                             repeatType            :  calendarDetail.calendarDetail.repeatType,
                             repeatUntil           :  calendarDetail.calendarDetail.repeatUntil,
                             repeatWeekdayPosition :  calendarDetail.calendarDetail.repeatWeekdayPosition,
                             repeatWeeklyFriday    :  calendarDetail.calendarDetail.repeatWeeklyFriday,
                             repeatWeeklyMonday    :  calendarDetail.calendarDetail.repeatWeeklyMonday,
                             repeatWeeklySaturday  :  calendarDetail.calendarDetail.repeatWeeklySaturday,
                             repeatWeeklySunday    :  calendarDetail.calendarDetail.repeatWeeklySunday,
                             repeatWeeklyThursday  :  calendarDetail.calendarDetail.repeatWeeklyThursday,
                             repeatWeeklyTuesday   :  calendarDetail.calendarDetail.repeatWeeklyTuesday,
                             repeatWeeklyWednesday :  calendarDetail.calendarDetail.repeatWeeklyWednesday
                            },
              description : calendarDetail.calendarDetail.description,
              dateFrom    : transfromStringtoDate(calendarDetail.calendarDetail.dateFrom) ,
              dateTo      : transfromStringtoDate(calendarDetail.calendarDetail.dateTo),
              timeFrom    : transfromStringtoDateTime(calendarDetail.calendarDetail.timeFrom),
              phone       : calendarDetail.calendarDetail.client? calendarDetail.calendarDetail.client.phone:'',
              services    : calendarDetail.calendarDetail.services,
              registration: calendarDetail.calendarDetail.registrationNumber,
              budget      : calendarDetail.calendarDetail.budget,
              orFleet     : calendarDetail.calendarDetail.orFleet,
              client      : calendarDetail.calendarDetail.client
           })
        })
      });
      this.loading=false
 
    })

  }

  onKey(value){

    this.searchText= value
    this.searchEvent()

  }

  clearEvents(event){
    event.stopPropagation();
    event.preventDefault();
    this.initializeValues();
    this.searchText=''
  }

   
}
